import React, { useState, useEffect, useContext } from "react";
import {
  Col,
  Row,
  Card,
  Skeleton,
  Result,
  Input,
} from "antd";
import {
  SearchOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { listTools } from "../../services/tools";
import AuthContext from "../../contexts/auth-context";
import ToolCard from "../../components/cards/tool-card/tool-card";
import FeedHeader from "../../components/typography/feed-header";

const LoadingSkeletons = () => {
  return (
    <>
      {[1, 2, 3, 4, 5].map((x) => (
        <Col span={24}>
          <Card size="small" style={{ cursor: "pointer" }}>
            <Row justify="space-between">
              <Col xs={{ span: 24 }} sm={{ span: 18 }}>
                <Skeleton active />
              </Col>
              <Col
                xs={{ span: 0 }}
                sm={{ span: 6 }}
                style={{ textAlign: "end" }}
              >
                <Skeleton.Avatar active size={100} shape="square" />
              </Col>
            </Row>
          </Card>
        </Col>
      ))}
    </>
  );
};

const ToolsList = () => {
  const { token } = useContext(AuthContext);

  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [tools, setTools] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const loadData = async () => {
    setIsLoading(true);
    try {
      const { results: allTools } = await listTools(token, { limit: 100 });
      setTools(allTools);
    } catch {
      console.log("Errror!");
    } finally {
      setIsLoading(false);
    }
  };

  const onUpdateTool = async (tool) => {
    const filtered = tools.filter((t) => t.id !== tool.id);
    setTools([...filtered, { ...tool, is_favorite: !tool.is_favorite }]);
  };

  useEffect(() => {
    loadData();
  }, []);

  if (hasError)
    return (
      <Result
        title="Uh, something went wrong.."
        subTitle="Try refreshing the page, if that doesn't do it then something is broken."
        icon={<ExclamationCircleOutlined />}
      />
    );

  return (
    <>
      <Row justify="space-between" align="top">
        <Col>
          <FeedHeader title="Tools" />
        </Col>
        <Col>
          <Input
            onChange={(e) => setSearchTerm(e.target.value)}
            value={searchTerm}
            addonBefore={<SearchOutlined />}
            placeholder="Search Tools"
            allowClear
          />
        </Col>
      </Row>
      <Row gutter={[0, 12]}>
        {isLoading && <LoadingSkeletons />}
        {!isLoading &&
          tools.map((t) => (
            <Col span={24} key={t.id}>
              <ToolCard tool={t} onUpdateActionClick={onUpdateTool} />
            </Col>
          ))}
      </Row>
    </>
  );
};

export default ToolsList;
