import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Skeleton,
  Button,
  Card,
  Col,
  Row,
  Space,
  Typography,
  Breadcrumb,
  Alert,
  notification,
  Select,
} from "antd";
import {
  ShareAltOutlined,
  EditOutlined,
  ArrowRightOutlined,
  ArrowLeftOutlined,
  ReadOutlined,
  CheckOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import AuthContext from "../../contexts/auth-context";
import { getCourse, getLesson, toggleLessonComplete } from "../../services/courses";
import { isValidYouTubeUrl } from "../../utils";
import Markdown from "react-markdown";
import styles from "./lesson-detail.module.scss";
import JamtrackCard from "../../components/cards/jamtrack-card";
import BlogCard from "../../components/cards/blog-card/blog-card";
import ToolCard from "../../components/cards/tool-card/tool-card";
import ChordProgCard from "../../components/cards/chord-prog-card/chord-prog-card";
import FeedContext from "../../contexts/feed-context";
import { getJamtrack } from "../../services/jamtracks";
import { getBlog } from "../../services/blogs";
import { getTool } from "../../services/tools";
import { getChordProg } from "../../services/chord-progressions";
import VideoPlayer from "../../components/video-player/video-player";
import mediaItemTypes from "../../enums/media-item-types";
import { getPlayalong } from "../../services/yt-playalongs";
import YTPlayalongCard from "../../components/cards/yt-playalong-card/yt-playalong-card";

const SkeletonLessonDetail = () => {
  return (
    <>
      <Skeleton active paragraph={{ rows: 0 }} />
      <Row>
        <Col span={24}>
          <Card size="small">
            <Skeleton active paragraph={{ rows: 10 }} />
          </Card>
        </Col>
      </Row>
    </>
  )
}

const ActionButtons = ({ lesson }) => {
  const { tokenClaim } = useContext(AuthContext);
  const nagivate = useNavigate();

  const handleEditClick = () => nagivate(`/courses/${lesson.course}/lessons/${lesson.id}/edit`);

  const handleClickShare = () => {
    let hostname = window.location.hostname;
    if (hostname === "localhost") hostname = "localhost:3000";
    navigator.clipboard.writeText(`${hostname}/blogs/${lesson.id}`);

    notification.open({
      message: "Link copied to clipboard!",
      placement: "bottomRight",
    });
  };
  return (
    <Space>
      {tokenClaim.username === lesson.user.username && (
        <Button size="small" type="text" onClick={handleEditClick}>
          <EditOutlined />
        </Button>
      )}
      <Button size="small" type="text" onClick={handleClickShare}>
        <ShareAltOutlined />
      </Button>
    </Space>
  );
};

const LessonContentCard = ({ lesson, course }) => {
  const navigate = useNavigate();
  const content = lesson.content;
  return (
    <Card size="small">
      <Typography.Title level={4} style={{ marginBottom: 0 }}>{lesson.title}</Typography.Title>
      <Row justify="space-between" align="middle">
        <Col>
          <Typography.Link onClick={() => navigate(`/courses/${course?.id}`)}>
            <small>
              {course?.title}
            </small>
          </Typography.Link>
        </Col>
        <Col>
          <ActionButtons lesson={lesson} />
        </Col>
      </Row>
      {lesson.video_setting !== "no_video" && (
        <>
          <div>
            {lesson.video_youtube_url && isValidYouTubeUrl(lesson.video_youtube_url) && (
              <div style={{ marginTop: 10 }}>
                <VideoPlayer
                  src={lesson.video_youtube_url}
                  mediaItem={{
                    ...lesson,
                    type: mediaItemTypes.LESSON
                  }}
                />
              </div>
            )}
          </div>
        </>
      )}
      <br />
      <div className={styles["content-container"]}>
        <Markdown className={styles["markdown-component"]}>{content}</Markdown>
      </div>
    </Card>
  );
};

const LessonResourcesCard = ({
  jamtrackIds,
  blogIds,
  toolIds,
  chordProgIds,
  playalongIds
}) => {
  const { token } = useContext(AuthContext);

  // feed is necessary because it keeps jamtracks synced across other components
  const { feed, setFeed } = useContext(FeedContext);
  const [blogs, setBlogs] = useState([]);
  const [tools, setTools] = useState([]);
  const [chordProgressions, setChordProgressions] = useState([]);
  const [playalongs, setPlayalongs] = useState([]);

  const [selectedResources, setSelectedResources] = useState("All Resources")
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);

  const loadData = async () => {
    try {
      const jamtrackData = await Promise.all(jamtrackIds.map(async (id) => {
        return await getJamtrack(token, id)
      }));
      const blogData = await Promise.all(blogIds.map(async (id) => {
        return await getBlog(token, id)
      }));
      const toolData = await Promise.all(toolIds.map(async (id) => {
        return await getTool(token, id)
      }));
      const chordProgData = await Promise.all(chordProgIds.map(async (id) => {
        return await getChordProg(token, id)
      }));
      const playalongData = await Promise.all(playalongIds.map(async (id) => {
        return await getPlayalong(token, id)
      }));

      setFeed(jamtrackData);
      setBlogs(blogData);
      setTools(toolData);
      setChordProgressions(chordProgData);
      setPlayalongs(playalongData)
    } catch {
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  }

  const jamtrackChildren = (
    <Row gutter={[12, 12]}>
      {feed.map((jt) => (
        <Col span={24}>
          <JamtrackCard jamtrack={jt} />
        </Col>
      ))}
    </Row>
  )

  const blogChildren = (
    <Row gutter={[12, 12]}>
      {blogs.map((b) => (
        <Col span={24}>
          <BlogCard blog={b} />
        </Col>
      ))}
    </Row>
  )

  const toolChildren = (
    <Row gutter={[12, 12]}>
      {tools.map((t) => (
        <Col span={24}>
          <ToolCard tool={t} />
        </Col>
      ))}
    </Row>
  )

  const chordProgChildren = (
    <Row gutter={[12, 12]}>
      {chordProgressions.map((cp) => (
        <Col span={24}>
          <ChordProgCard chordProg={cp} />
        </Col>
      ))}
    </Row>
  )

  const playalongChildren = (
    <Row gutter={[12, 12]}>
      {playalongs.map((p) => (
        <Col span={24}>
          <YTPlayalongCard ytPlayalong={p} />
        </Col>
      ))}
    </Row>
  )

  const allChildren = (
    <>
      <Row gutter={[12, 12]}>
        {jamtrackIds.length > 0 && (
          <Col span={24}>
            {jamtrackChildren}
          </Col>
        )}
        {blogIds.length > 0 && (
          <Col span={24}>
            {blogChildren}
          </Col>
        )}
        {toolIds.length > 0 && (
          <Col span={24}>
            {toolChildren}
          </Col>
        )}
        {chordProgIds.length > 0 && (
          <Col span={24}>
            {chordProgChildren}
          </Col>
        )}
        {playalongIds.length > 0 && (
          <Col span={24}>
            {playalongChildren}
          </Col>
        )}
      </Row>
    </>
  )

  const resourceSelectMap = {
    "All Resources": allChildren,
  }

  if (jamtrackIds.length) resourceSelectMap["Jamtracks"] = jamtrackChildren;
  if (blogIds.length) resourceSelectMap["Blogs"] = blogChildren;
  if (chordProgIds.length) resourceSelectMap["Chord Progessions"] = chordProgChildren;
  if (toolIds.length) resourceSelectMap["Tools"] = toolChildren;
  if (playalongIds.length) resourceSelectMap["YouTube Playalongs"] = playalongChildren;

  useEffect(() => {
    loadData();
  }, [])

  return (
    <Card size="small">
      <Row justify="space-between" align="top">
        <Col>
          <Typography.Title level={5} style={{ marginBottom: 10 }}>
            Resources
          </Typography.Title>
        </Col>
        <Col>
          <Select
            size="small"
            value={selectedResources}
            options={Object.keys(resourceSelectMap).map((k) => ({
              value: k,
              label: k,
            }))}
            onChange={(v) => setSelectedResources(v)}
          />
        </Col>
      </Row>
      {hasError && (
        <Alert type="error" description="We are unable to load resources for this lesson. Please try again later." />
      )}
      {isLoading ? (
        <>
          <Skeleton active paragraph={{ rows: 2 }} />
        </>
      ) : (
        <div>
          {resourceSelectMap[selectedResources]}
        </div>
      )}
    </Card>
  )
}

const LessonCompletedCard = ({ lesson, onMarkComplete }) => {
  const cardTitle = lesson.is_completed ? "Congratulations" : "Finish the Lesson"
  return (
    <Card size="small">
      <Row justify="space-between" align="middle">
        <Col>
          <Typography.Title level={5} style={{ marginBottom: 0 }}>{cardTitle}</Typography.Title>
        </Col>
        <Col>
          {lesson.is_completed ? (
            <Typography.Text type="success">
              <Space>
                <CheckCircleOutlined />
                Lesson Completed
              </Space>
            </Typography.Text>
          ) : (
            <Button onClick={() => onMarkComplete(lesson)} icon={<CheckOutlined />}>
              Mark Completed
            </Button>
          )}

        </Col>
      </Row>
      <Row justify="end">
      </Row>
    </Card >
  )
}

const LessonDetailPage = () => {
  const { token, isLoggedIn } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();

  const [lesson, setLesson] = useState();
  const [course, setCourse] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const { lessonId, courseId } = useParams();

  const orderedLessons = course?.lessons.sort((a, b) => a.order - b.order) || [];
  const currentLessonIndex = orderedLessons.indexOf(orderedLessons.find((l) => l.id === lessonId));
  const nextLesson = currentLessonIndex === orderedLessons.length - 1 ? null : orderedLessons[currentLessonIndex + 1];
  const prevLesson = currentLessonIndex === 0 ? null : orderedLessons[currentLessonIndex - 1];

  const hasResources = lesson?.jamtracks.length > 0 ||
    lesson?.blogs.length > 0 ||
    lesson?.tools.length > 0 ||
    lesson?.chord_progressions.length > 0 ||
    lesson?.yt_playalongs.length > 0;

  const breadcrumbs = [
    {
      href: null,
      onClick: () => navigate("/courses/recent"),
      title:
        <Space><ReadOutlined /> Courses</Space>,
    },
    {
      href: null,
      onClick: () => navigate(`/courses/${course.id}`),
      title: course?.title || "",
    },
    {
      title: `Lesson ${currentLessonIndex + 1}`,
    },
  ]

  const onMarkComplete = async (lesson) => {
    try {
      const completeStatus = await toggleLessonComplete(token, lesson);
      setLesson((lesson) => ({ ...lesson, is_completed: completeStatus.is_completed }));
    } catch {
      notification.open({
        message: "An error occured",
        description: "We are unable to mark this lesson as complete. Please try again later.",
        placement: "bottomRight",
      });
    }
  }

  const loadData = async () => {
    setIsLoading(true);
    try {
      const lessonData = await getLesson(token, lessonId);
      const courseData = await getCourse(token, courseId);
      setLesson(lessonData);
      setCourse(courseData);
    } catch {
      notification.open({
        message: "We didn't find what your looking for...",
        description: "We are redirecting you to the home page.",
        placement: "bottomRight",
      });
      navigate("/");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadData({});
  }, [location.pathname]);

  if (isLoading) {
    return (
      <Row>
        <Col span={24}>
          <SkeletonLessonDetail />
        </Col>
      </Row>
    )
  }

  return (
    <>
      <Row gutter={[0, 12]}>
        <Col style={{ margin: "0px 8px 8px 8px" }} span={24}>
          <Breadcrumb items={breadcrumbs} />
        </Col>
      </Row>
      <Row gutter={[0, 12]} justify="center">
        <Col span={24}>
          <LessonContentCard lesson={lesson} course={course} />
        </Col>
        {hasResources && (
          <Col span={24}>
            <LessonResourcesCard
              jamtrackIds={lesson.jamtracks}
              blogIds={lesson.blogs}
              toolIds={lesson.tools}
              chordProgIds={lesson.chord_progressions}
              playalongIds={lesson.yt_playalongs}
            />
          </Col>
        )}
        {isLoggedIn && lesson.is_published && (
          <Col span={24}>
            <LessonCompletedCard lesson={lesson} onMarkComplete={onMarkComplete} />
          </Col>
        )}
      </Row>
      <br />
      <Row justify="space-between">
        <Col>
          {prevLesson && (
            <div>
              <Button type="link" onClick={() => navigate(`/courses/${courseId}/lessons/${prevLesson.id}`)}>
                <Space>
                  <ArrowLeftOutlined />
                  Previous Lesson
                </Space>
              </Button>
            </div>
          )}
        </Col>
        <Col>
          {nextLesson && (
            <div>
              <Button type="link" onClick={() => navigate(`/courses/${courseId}/lessons/${nextLesson.id}`)}>
                <Space>
                  Next Lesson
                  <ArrowRightOutlined />
                </Space>
              </Button>
            </div>
          )}
        </Col>
      </Row>
    </>
  );
};

export default LessonDetailPage;
