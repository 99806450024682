import { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import MusicPlayerContext from "../contexts/music-player-context";
import mediaItemTypes from "../enums/media-item-types";

// takes care of scrolling to top and stopping YT videos
// when navigating to new page

const NavigateHandler = () => {
  const { pathname } = useLocation();
  const { current, setCurrent, pause } = useContext(MusicPlayerContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

export default NavigateHandler;
