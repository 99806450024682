import React, { useContext, useState } from "react";
import { Col, Row, Typography, Card, Space, notification } from "antd";
import { PlusOutlined, MinusOutlined, } from "@ant-design/icons";
import AuthContext from "../../../contexts/auth-context";
import styles from "./tool-card.module.scss";
import { toggleToolFavorite } from "../../../services/tools";
import { useNavigate } from "react-router-dom";

const ToolCard = ({ tool }) => {
  const navigate = useNavigate();
  const { isLoggedIn, token, toolbox, setToolbox } = useContext(AuthContext);
  const [isFavorite, setIsFavorite] = useState(tool.is_favorite);

  const onClickToolAction = async (t) => {
    try {
      await toggleToolFavorite(token, { ...t, is_favorite: isFavorite });
      setIsFavorite((prev) => !prev);

      const updatedToolbox = isFavorite
        ? toolbox.filter((t) => t.id !== tool.id)
        : [...toolbox, { ...tool, is_favorite: !isFavorite }];

      setToolbox(updatedToolbox);

      notification.open({
        message: isFavorite ? "Tool Removed" : "Tool Added",
        placement: "bottomRight",
      });
    } catch {
      notification.open({
        message: "Uh oh, something went wrong.",
        description:
          "We were unable to add this tool to your toolbox. Please try again later",
        placement: "bottomRight",
      });
    }
  };

  const getToolActionButton = (tool) => {
    if (!isLoggedIn) {
      return null;
    }

    if (isFavorite) {
      return [
        <div onClick={() => onClickToolAction(tool)}>
          <Space>
            <MinusOutlined />
            Remove from Toolbox
          </Space>
        </div>,
      ];
    }

    return [
      <div onClick={() => onClickToolAction(tool)}>
        <Space>
          <PlusOutlined />
          Add to Toolbox
        </Space>
      </div>,
    ];
  };
  return (
    <Card size="small" actions={getToolActionButton(tool)}>
      <Row justify="space-between">
        <Col span={24}>
          <Typography.Title level={5} onClick={() => navigate(tool.key)}>
            <Space className={styles["tool-title"]}>
              {tool.title}
            </Space>
          </Typography.Title>
          <Typography.Paragraph type="secondary">
            {tool.description}
          </Typography.Paragraph>
        </Col>
      </Row>
    </Card>
  );
};

export default ToolCard;
