import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Card,
  Space,
  Typography,
  Avatar,
  Row,
  Col,
  notification,
  Tag,
  Tooltip,
} from "antd";
import {
  HeartFilled,
  HeartOutlined,
  ShareAltOutlined,
  GlobalOutlined,
  TableOutlined,
  LoadingOutlined,
  PauseOutlined,
  CaretRightFilled,
  OrderedListOutlined,
  KeyOutlined,
  RetweetOutlined,
  FieldTimeOutlined,
} from "@ant-design/icons";
import mediaItemTypes from "../../../enums/media-item-types";
import { togglePlayalongFavorite } from "../../../services/yt-playalongs";
import AuthContext from "../../../contexts/auth-context";
import { genreMap } from "../../../enums/genres";
import MusicPlayerContext from "../../../contexts/music-player-context";
import { keysMap, tonalityMap } from "../../../enums/keys";

const YTPlayalongCard = ({ ytPlayalong }) => {
  const {
    token,
    isLoggedIn
  } = useContext(AuthContext);
  const {
    current,
    isPlaying,
    isAudioLoading,
    trackProgress,
    startNew,
    pause,
    play,
    addToQueue,
  } = useContext(MusicPlayerContext);

  const navigate = useNavigate();

  const [isFavorited, setIsFavorited] = useState(ytPlayalong.is_favorite);

  const isCurrent = current && current.id === ytPlayalong.id;

  const handleFavoriteClick = async () => {
    try {
      await togglePlayalongFavorite(token, ytPlayalong);
      setIsFavorited((prev) => !prev);
      notification.open({
        message: `YouTube Play Along ${isFavorited ? "Unfavorited" : "Favorited"}`,
        description:
          `${ytPlayalong.title} has been ${ytPlayalong.is_favorite ? "removed from" : "added to"} your list of favorites.`,
        placement: "bottomRight",
      });
    } catch (error) {
      notification.open({
        message: "Uh oh, something went wrong.",
        description:
          "We were unable to add or remove this to your favorites. Please try again later",
        placement: "bottomRight",
      });
    }
  };

  const addTrackToQueue = (playalong) => {
    addToQueue(playalong);
    notification.open({
      message: "Added to Queue",
      placement: "bottomRight",
    });
  };

  const getPlayButton = () => {
    if (isCurrent && (isAudioLoading || trackProgress <= 0)) {
      return (
        <Space>
          <LoadingOutlined />
        </Space>
      );
    }
    if (!isCurrent) {
      return (
        <div onClick={() => startNew({ ...ytPlayalong, type: mediaItemTypes.PLAYALONG })}>
          <Tooltip title="Play">
            <CaretRightFilled />
          </Tooltip>
        </div>
      );
    }

    if (isCurrent && isPlaying) {
      return (
        <div onClick={() => pause()}>
          <Tooltip title="Pause">
            <PauseOutlined />
          </Tooltip>
        </div>
      );
    }

    return (
      <div onClick={() => play()}>
        <CaretRightFilled />
      </div>
    );
  };

  const getActionButtons = () => {
    const actions = [
      getPlayButton(),
      <div onClick={() => addTrackToQueue({ ...ytPlayalong, type: mediaItemTypes.PLAYALONG })}>
        <Tooltip title="Add to Queue">
          <OrderedListOutlined />
        </Tooltip>
      </div>,
    ];

    if (isLoggedIn) {
      actions.push(
        <div onClick={handleFavoriteClick}>
          {isFavorited ? <HeartFilled /> : <HeartOutlined />}
        </div>
      );
    }

    return actions;
  };

  const handleClickShare = () => {
    let hostname = window.location.hostname;
    if (hostname === "localhost") hostname = "localhost:3000";
    navigator.clipboard.writeText(hostname + `/yt-playalongs/${ytPlayalong.id}`);
    notification.open({
      message: "Copied to Clipboard",
      description:
        "A direct link to this jam track has been copied to your clipboard.",
      placement: "bottomRight",
    });
  };

  const moreActions = [
    {
      key: "share",
      label: "Share",
      icon: <ShareAltOutlined />,
      onClick: handleClickShare,
    },
  ];

  return (
    <Card
      size="small"
      actions={getActionButtons()}
    >
      <Row justify="space-between">
        <Col span={20}>
          <Space align="start" size="middle">
            <Avatar
              shape="square"
              size="large"
              src={ytPlayalong.channel_data.logo}
            >
              {ytPlayalong.channel_data.name[0]}
            </Avatar>
            <Space direction="vertical" size="middle">
              <div>
                <Typography.Title level={5} style={{ marginBottom: 0 }}>
                  {ytPlayalong.title}
                </Typography.Title>
                <Space
                  split={<Typography.Text type="secondary">•</Typography.Text>}
                >
                  <Typography.Text type="secondary">
                    <small>
                      {ytPlayalong.channel_data.name}
                    </small>
                  </Typography.Text>
                </Space>
              </div>
            </Space>
          </Space>
        </Col>
        {/* <Col>
          <div style={{ textAlign: "right" }}>
            <Space>
              <JamtrackActionButtons jamtrack={jamtrack} />
            </Space>
          </div>
        </Col> */}
      </Row>
      <Row style={{ marginTop: 10 }}>
        <Col span={24}>
          <Space wrap>
            {ytPlayalong.genre && (
              <Tag bordered={false} icon={<GlobalOutlined />}>
                {genreMap[ytPlayalong.genre]}
              </Tag>
            )}
            {ytPlayalong.key && ytPlayalong.tonality && (
              <Tag bordered={false} icon={<KeyOutlined />}>
                {`${keysMap[ytPlayalong.key]} ${tonalityMap[ytPlayalong.tonality]}`}
              </Tag>
            )}
            {ytPlayalong.tempo && (
              <Tag bordered={false} icon={<FieldTimeOutlined />}>
                {ytPlayalong.tempo} BPM
              </Tag>
            )}
            {ytPlayalong.time_sig && (
              <Tag bordered={false} icon={<RetweetOutlined />}>
                {ytPlayalong.time_sig} TIME
              </Tag>
            )}
            {ytPlayalong.is_transcribed && (
              <Tag bordered={false} icon={<TableOutlined />}>
                Live Chords
              </Tag>
            )}
          </Space>
        </Col>
      </Row>
    </Card>
  )
};

export default YTPlayalongCard;
