import React, { useContext, useEffect, useState } from "react";
import {
  Layout,
  Row,
  Col,
  Card,
  Affix,
  Space,
  Skeleton,
  Typography,
  Button,
  Alert,
  notification,
} from "antd";
import {
  LoginOutlined,
  NotificationOutlined,
  MinusOutlined,
} from "@ant-design/icons";
import Container from "../container/container";
import { useNavigate, useLocation } from "react-router-dom";
import AuthContext from "../../../contexts/auth-context";
import { listChordsOfTheDay } from "../../../services/chords";
import { guitarChords, guitar } from "../../../chords";
import Chord from "@tombatossals/react-chords/lib/Chord";
import FooterLinks from "../../footer-links/footer-links";
import { retrieveUser } from "../../../services/users";
import { listTools, toggleToolFavorite } from "../../../services/tools";
import NewJamtrackModal from "../../new-jamtrack-modal/new-jamtrack-modal";
import defaultCoverImage from "../../../media/images/default_profile_background.jpg";
import styles from "./content-container.module.scss";

const { Content } = Layout;

const LeftAffixWrapper = ({ children, onSetIsAffixed }) => {
  return (
    <Col xs={{ span: 0 }} md={{ span: 24 }}>
      <Affix
        offsetTop={76} // header + 12px margin
        onChange={(affixed) => onSetIsAffixed(affixed)}
      >
        {children}
      </Affix>
    </Col>
  );
};

const LeftSideContent = () => {
  const navigate = useNavigate();
  const {
    isLoggedIn,
    token,
    tokenClaim: { user_id: userId, is_creator: isCreator, username },
    unreadNotifCount,
    getUnreadNotifCount,
    toolbox,
    setToolbox,
  } = useContext(AuthContext);

  const [isNewModalOpen, setIsNewModalOpen] = useState(false);
  const [isAffixed, setIsAffixed] = useState(false);
  const [userProfile, setUserProfile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  const loadData = async () => {
    setIsLoading(true);
    setHasError(false);

    try {
      if (token) getUnreadNotifCount();
      if (isCreator) {
        const userResult = await retrieveUser(token, userId);
        setUserProfile(userResult);
      }
      const { results: tools } = await listTools(token, { is_favorite: 1 });
      setToolbox(tools);
    } catch (error) {
      setHasError(true);
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const onRemoveTool = async (tool) => {
    await toggleToolFavorite(token, tool);
    setToolbox(toolbox.filter((t) => t.id !== tool.id));
    notification.open({
      message: "Tool Removed",
      description: `${tool.title} has been removed from your toolbox.`,
      placement: "bottomRight",
    });
  };

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    const interval = setInterval(getUnreadNotifCount, 100000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  if (isLoading) {
    return (
      <Card>
        <Skeleton active></Skeleton>
      </Card>
    );
  }

  return (
    <Row gutter={[12, 12]}>
      {isLoggedIn && (
        <Col xs={0} lg={24}>
          <Card size="small">
            <Typography.Text level={5}>
              <b>Welcome, {username}!</b>
            </Typography.Text>
            {unreadNotifCount > 0 && (
              <Alert
                style={{ marginTop: 10 }}
                showIcon
                icon={<NotificationOutlined />}
                message={
                  <b>
                    {unreadNotifCount} New{" "}
                    {unreadNotifCount === 1
                      ? "Notification"
                      : "Notifications"}
                  </b>
                }
                action={
                  <Button
                    type="primary"
                    ghost
                    size="small"
                    onClick={() => navigate("/notifications")}
                  >
                    View
                  </Button>
                }
              />
            )}
          </Card>
        </Col>
      )}
      <LeftAffixWrapper onSetIsAffixed={setIsAffixed}>
        <Row gutter={[0, 12]} justify="center">
          {!isLoggedIn && (
            <Col span={24}>
              <Card
                title="Login to Jamtrackers"
                size="small"
                actions={[
                  <div onClick={() => navigate("/register")}>Create Free Account</div>,
                ]}
              >
                <Card.Meta description="Login to access all content, features, and resources." />
                <br />
                <Row>
                  <Col span={24}>
                    <Button
                      type="primary"
                      size="large"
                      block
                      onClick={() => navigate("/login")}
                    >
                      <Space>
                        <LoginOutlined />
                        <div>Login</div>
                      </Space>
                    </Button>
                  </Col>
                </Row>
              </Card>
            </Col>
          )}

          {isLoggedIn && (
            <Col xs={0} lg={24}>
              <Card
                title="My Toolbox"
                size="small"
                actions={[
                  <div onClick={() => navigate("/tools")}>Browse Tools</div>,
                ]}
              >
                {toolbox.length === 0 && (
                  <Row justify="center">
                    <Col span={24}>
                      <Typography.Text type="secondary">
                        Your toolbox is empty. Try filling it up with some
                        tools.
                      </Typography.Text>
                    </Col>
                  </Row>
                )}
                {toolbox.map((t) => (
                  <Row justify="space-between" style={{ padding: 3 }}>
                    <Col>
                      <Typography.Link onClick={() => navigate(t.key)}>
                        {t.title}
                      </Typography.Link>
                    </Col>
                    <Col>
                      <Button
                        size="small"
                        type="text"
                        onClick={() => onRemoveTool(t)}
                      >
                        <MinusOutlined />
                      </Button>
                    </Col>
                  </Row>
                ))}
              </Card>
            </Col>
          )}
        </Row>
      </LeftAffixWrapper >
      <NewJamtrackModal isOpen={isNewModalOpen} setIsOpen={setIsNewModalOpen} />
    </Row >
  );
};

const RightSideContent = () => {
  const { token, isLoggedIn, tokenClaim: { is_creator: isCreator } } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();

  const [isLoading, setIsLoading] = useState([]);
  const [hasError, setHasError] = useState(false);
  const [chordOfTheDay, setChordOfTheDay] = useState(null);

  const loadData = async () => {
    setIsLoading(true);
    setHasError(false);

    try {
      const dayChords = await listChordsOfTheDay(token, { limit: 1 });
      if (dayChords.results.length > 0) {
        const chord = dayChords.results[0];
        const identifiedChord = chord
          ? guitarChords.chords[chord.key].find(
            (c) => c.suffix === chord.suffix
          ).positions[chord.position]
          : false;

        const completeChordData = {
          chord: identifiedChord,
          key: chord.key,
          tonality: chord.suffix,
        };
        setChordOfTheDay(identifiedChord ? completeChordData : null);
      }
    } catch (error) {
      setHasError(true);
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  if (isLoading) {
    return (
      <Row gutter={[0, 12]} justify="center">
        <Col span={24}>
          <Card>
            <Skeleton active title loading={isLoading}></Skeleton>
          </Card>
        </Col>
      </Row>
    );
  }

  return (
    <Row gutter={[0, 12]} justify="center">
      {chordOfTheDay && location.pathname !== "/tools/chord-of-the-day" && (
        <Col xs={0} md={24}>
          <Card
            size="small"
            title="Chord of the Day"
            actions={[
              <div onClick={() => navigate("/tools/chord-explorer")}>
                Explore Chords
              </div>,
            ]}
          >
            <Chord chord={chordOfTheDay.chord} instrument={guitar} />
            <div style={{ textAlign: "center" }}>
              <Typography.Text>
                <b>{chordOfTheDay.key + " " + chordOfTheDay.tonality}</b>
              </Typography.Text>
            </div>
          </Card>
        </Col>
      )}

      {!isLoggedIn && (
        <Col xs={24} md={0}>
          <Card
            size="small"
            actions={[
              <div onClick={() => navigate("/register")}>Create Free Account</div>,
            ]}
          >
            <Typography.Title level={4}>
              Login to Jamtrackers
            </Typography.Title>
            <Typography.Paragraph>
              Log in to access favorited content. Don't have an account? Create one for free.
            </Typography.Paragraph>
            <br />
            <Row>
              <Col span={24}>
                <Button
                  type="primary"
                  size="large"
                  block
                  onClick={() => navigate("/login")}
                >
                  <Space>
                    <LoginOutlined />
                    <div>Login</div>
                  </Space>
                </Button>
              </Col>
            </Row>
          </Card>
        </Col>
      )}
      <Col>
        <div style={{ padding: "0px 20px" }}>
          <FooterLinks />
        </div>
      </Col>
    </Row>
  );
};

const ContentContainer = ({ children }) => {
  return (
    <Container>
      <Content
        style={{
          margin: "12px 0px",
          overflow: "initial",
          minHeight: "100vh", // TODO calcuate height with header
        }}
      >
        <Row gutter={[12, 12]} justify="center">
          <Col
            xs={{ span: 0, order: 2 }}
            md={{ span: 0, order: 1 }}
            lg={{ span: 6, order: 1 }}
            xl={{ span: 6, order: 1 }}
          >
            <LeftSideContent />
          </Col>
          <Col
            xs={{ span: 24, order: 1 }}
            md={{ span: 24, order: 2 }}
            lg={{ span: 12, order: 2 }}
            xl={{ span: 12, order: 2 }}
          >
            {children}
          </Col>
          <Col
            xs={{ span: 24, order: 3 }}
            md={{ span: 0, order: 0 }}
            lg={{ span: 6, order: 3 }}
            xl={{ span: 6, order: 3 }}
          >
            <RightSideContent />
          </Col>
        </Row>
      </Content>
    </Container>
  );
};

export default ContentContainer;
