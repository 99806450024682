import React from "react";
import { Col, Row } from "antd";
import { genreMap } from "../../enums/genres";
import GenreCard from "../../components/cards/genre-card";
import FeedHeader from "../../components/typography/feed-header";

const GenreListPage = () => {
  const genreKeys = Object.keys(genreMap);
  return (
    <>
      <Row justify="space-between">
        <Col>
          <FeedHeader title="All Genres" />
        </Col>
      </Row>
      <Row gutter={[12, 12]}>
        {genreKeys.map((g) => (
          <Col xs={{ span: 12 }} lg={{ span: 8 }}>
            <GenreCard name={genreMap[g]} encodedName={g} key={g} />
          </Col>
        ))}
      </Row>
    </>
  );
};

export default GenreListPage;
