import React from "react";
import { Typography } from "antd";
import GrayIconSvg from "../custom-icons/gray-icon-svg";

const NowPlaying = ({ currentTrack }) => {
  return (
    <div
      size="small"
      style={{
        marginTop: 14,
        marginBottom: 14,
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      {!currentTrack ? (
        <GrayIconSvg />
      ) : (
        <>
          <div>
            <Typography.Text
              strong
              style={{ display: "block", lineHeight: "100%", width: "100%" }}
            >
              <small>
                {currentTrack.title}
              </small>
            </Typography.Text>
          </div>

          <div style={{ display: "block", lineHeight: "100%" }}>
            <Typography.Text type="secondary">
              <small>
                {currentTrack.channel_data.name}
              </small>
            </Typography.Text>
          </div>
        </>
      )
      }
    </div >
  );
};

export default NowPlaying;
