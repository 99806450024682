import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { Badge, Col, FloatButton, Layout, Row, Typography, theme } from "antd";
import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";
import styles from "./custom-layout.module.scss";
import SiderMenu from "../sider-menu";
import ControlsHeader from "../controls-header/controls-header";
import ContentContainer from "../content-container/content-container";
import CookieAlert from "../../cookie-alert/cookie-alert";

const { Sider } = Layout;

const CustomLayout = ({ children }) => {
  const {
    token: { colorPrimary },
  } = theme.useToken();

  const location = useLocation();

  const [isCollapsed, setIsCollapsed] = useState(
    window.innerWidth > 768 ? false : true
  );

  const basicRoutes = [
    "/login",
    "/register",
    "/verification",
    "/error",
    "/transcribe",
    "/faqs",
    "/cookie-policy",
    "/privacy-policy",
    "/about",
    "/terms-and-conditions",
    "/forgot-password",
    "/reset-password",
    "/edit-profile",
    "/staff/youtube-capture",
    "/creator-application",
  ];
  const isSimpleLayout = basicRoutes.some((route) =>
    location.pathname.includes(route)
  );

  if (isSimpleLayout)
    return <Layout style={{ minHeight: "100vh" }}>{children}</Layout>;

  return (
    <Layout hasSider>
      {/* Temporary float button until I figure out why sider trigger doesn't show up */}
      <Row>
        <Col xs={24} xl={0}>
          <FloatButton
            type="primary"
            shape="square"
            icon={isCollapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            onClick={() => setIsCollapsed(!isCollapsed)}
            style={{ left: isCollapsed ? 0 : 300, transition: ".2s", borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
          />
        </Col>
      </Row>
      <Sider
        trigger={isCollapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
        zeroWidthTriggerStyle={{
          top: "75%",
        }}
        breakpoint="xl"
        collapsedWidth={0}
        collapsed={isCollapsed}
        onCollapse={(collapsedState) => setIsCollapsed(collapsedState)}
        width={300}
        style={{
          height: "100vh",
          position: "fixed",
          left: 0,
          top: 0,
          bottom: 0,
          zIndex: 998,
        }}
      >
        <div
          style={{
            height: 32,
            margin: 16,
            textAlign: "center",
          }}
        >
          <Badge count="BETA" color={colorPrimary} size="small">
            <Typography.Title
              level={3}
              style={{ color: "white" }}
              className="brand-title"
            >
              Jamtrackers
            </Typography.Title>
          </Badge>
        </div>
        <SiderMenu />
      </Sider>
      <Layout className={styles["sider-margin-adjust"]}>
        <ControlsHeader />
        <ContentContainer>{children}</ContentContainer>
      </Layout>
      <CookieAlert />
    </Layout>
  );
};
export default CustomLayout;
