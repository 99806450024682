import React, { useContext } from "react";
import { Button } from "antd";
import {
  CaretRightFilled,
  PauseOutlined,
  StepForwardFilled,
  StepBackwardFilled,
  LoadingOutlined,
} from "@ant-design/icons";
import MusicPlayerContext from "../../../contexts/music-player-context";
import styles from "./audio-controls.module.scss";

const Controls = ({
  isPlaying,
  isAudioLoading,
  hideNextButton,
  hidePrevButton,
  onPlay,
  onPause,
  onPrevClick,
  onNextClick,
}) => {
  const middleButton = () => {
    if (isAudioLoading) {
      return (
        <Button type="text">
          <LoadingOutlined />
        </Button>
      );
    }

    if (!isPlaying) {
      return (
        <Button onClick={() => onPlay()} type="text">
          <CaretRightFilled />
        </Button>
      );
    }

    return (
      <Button onClick={() => onPause()} type="text">
        <PauseOutlined />
      </Button>
    );
  };
  return (
    <>
      <Button
        onClick={onPrevClick}
        disabled={hidePrevButton}
        type="text"
        className={styles["hide-on-small-screen"]}
      >
        <StepBackwardFilled />
      </Button>
      {middleButton()}
      <Button
        onClick={onNextClick}
        disabled={hideNextButton}
        type="text"
        size="large"
      >
        <StepForwardFilled />
      </Button>
    </>
  );
};

const DummyControls = () => {
  return (
    <>
      <Button disabled type="text" className={styles["hide-on-small-screen"]}>
        <StepBackwardFilled />
      </Button>
      <Button disabled type="text">
        <CaretRightFilled />
      </Button>
      <Button disabled type="text">
        <StepForwardFilled />
      </Button>
    </>
  );
};

const AudioControls = () => {
  const musicPlayerCtx = useContext(MusicPlayerContext);

  const {
    current,
    isPlaying,
    isAudioLoading,
    trackProgress,
    queue,
    history,
    play,
    pause,
    toNext,
    toPrev,
  } = musicPlayerCtx;

  if (!current) return <DummyControls />;

  return (
    <>
      <Controls
        isPlaying={isPlaying}
        isAudioLoading={isAudioLoading || trackProgress <= 0}
        hideNextButton={queue.length === 0}
        hidePrevButton={history.length <= 1}
        onPlay={play}
        onPause={pause}
        onPrevClick={toPrev}
        onNextClick={toNext}
      />
    </>
  );
};

export default AudioControls;
