import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Avatar,
  Button,
  Card,
  Col,
  Row,
  Space,
  Typography,
  notification,
} from "antd";
import {
  HeartFilled,
  HeartOutlined,
  ShareAltOutlined,
  EditOutlined,
} from "@ant-design/icons";
import SkeletonJamtrackCard from "../../components/cards/skeleton-jamtrack-card";
import AuthContext from "../../contexts/auth-context";
import { getBlog, toggleBlogFavorite } from "../../services/blogs";
import UserProfilePopover from "../../components/user-profile-popover/user-profile-popover";
import { getTimeAgo, isValidYouTubeUrl } from "../../utils";
import Markdown from "react-markdown";
import styles from "./blog-detail.module.scss";
import VideoPlayer from "../../components/video-player/video-player";
import mediaItemTypes from "../../enums/media-item-types";

const ActionButtons = ({ blog }) => {
  const { isLoggedIn, token, tokenClaim } = useContext(AuthContext);
  const nagivate = useNavigate();

  const [isFavorite, setIsFavorite] = useState(blog.is_favorite);

  const handleFavoriteClick = async () => {
    try {
      await toggleBlogFavorite(token, blog);

      setIsFavorite((fav) => !fav);

      notification.open({
        message: isFavorite ? "Blog Unfavorited" : "Blog Favorited",
        description: `This blog has been ${isFavorite ? "removed" : "added"
          } to your favorites list.`,
        placement: "bottomRight",
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleEditClick = () => nagivate(`/blogs/${blog.id}/edit`);

  const handleClickShare = () => {
    let hostname = window.location.hostname;
    if (hostname === "localhost") hostname = "localhost:3000";
    navigator.clipboard.writeText(`${hostname}/blogs/${blog.id}`);

    notification.open({
      message: "Link copied to clipboard!",
      placement: "bottomRight",
    });
  };
  return (
    <Space>
      {tokenClaim.username === blog.user.username && (
        <Button size="small" type="text" onClick={handleEditClick}>
          <EditOutlined />
        </Button>
      )}
      {isLoggedIn && (
        <Button size="small" type="text" onClick={handleFavoriteClick}>
          {isFavorite ? <HeartFilled /> : <HeartOutlined />}
        </Button>
      )}
      <Button size="small" type="text" onClick={handleClickShare}>
        <ShareAltOutlined />
      </Button>
    </Space>
  );
};

const BlogContentCard = ({ blog }) => {
  const content = blog.content; //.replace("\n", "bdbd");
  return (
    <Card size="small">
      <Typography.Title level={4}>{blog.title}</Typography.Title>
      <Row justify="space-between" align="middle">
        <Col>
          <div>
            <Space
              split={<Typography.Text type="secondary">•</Typography.Text>}
            >
              <UserProfilePopover
                user={blog.user}
                isFollowing={blog.in_following_feed}
              >
                <Space>
                  <Avatar src={blog.user.photo} />
                  <Typography.Link type="secondary">
                    {blog.user.username}
                  </Typography.Link>
                </Space>
              </UserProfilePopover>
              <Typography.Text type="secondary">
                {getTimeAgo(blog.created)}
              </Typography.Text>
            </Space>
          </div>
        </Col>
        <Col>
          <ActionButtons blog={blog} />
        </Col>
      </Row>
      {blog.video_setting !== "no_video" && (
        <>
          <div>
            {blog.video_youtube_url && isValidYouTubeUrl(blog.video_youtube_url) && (
              <div style={{ marginTop: 10 }}>
                <VideoPlayer
                  src={blog.video_youtube_url}
                  mediaItem={{ ...blog, type: mediaItemTypes.BLOG }}
                />
              </div>
            )}
          </div>
        </>
      )}
      {blog.video_setting === "no_video" && blog.photo && (
        <>
          <br />
          <div>
            <img
              className={styles["blog-detail-photo"]}
              src={blog.photo}
              alt={blog.title}
            />
          </div>
        </>
      )}
      <br />
      <div className={styles["content-container"]}>
        <Markdown className={styles["markdown-component"]}>{content}</Markdown>
      </div>
    </Card>
  );
};

const BlogDetailPage = () => {
  const { token } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();

  const [blog, setBlog] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const { blogId } = useParams();

  const loadData = async () => {
    setIsLoading(true);
    try {
      const blogData = await getBlog(token, blogId);
      setBlog(blogData);
    } catch {
      notification.open({
        message: "We didn't find what your looking for...",
        description: "We are redirecting you to the home page.",
        placement: "bottomRight",
      });
      navigate("/");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadData({});
  }, [location.pathname]);

  return (
    <Row gutter={[0, 12]} justify="center">
      <Col span={24}>
        {isLoading ? <SkeletonJamtrackCard /> : <BlogContentCard blog={blog} />}
      </Col>
    </Row>
  );
};

export default BlogDetailPage;
