import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../../contexts/auth-context";
import { Button, Col, Row, Typography } from "antd";
import { CaretRightFilled } from "@ant-design/icons";
import { listHighlights } from "../../services/faqs";
import { listPlayalongs } from "../../services/yt-playalongs";
import FeedContext from "../../contexts/feed-context";
import HomeSkeleton from "./home-skeleton";
import HeroCardCarousel from "../../components/hero-card-carousel/hero-card-carousel";
import SkeletonJamtrackCard from "../../components/cards/skeleton-jamtrack-card";
import { getAdFrequency } from "../../utils";
import InFeedAd from "../../components/ads/in-feed-ad/in-feed-ad";
import YTPlayalongCard from "../../components/cards/yt-playalong-card/yt-playalong-card";
import { useNavigate } from "react-router-dom";
import MusicPlayerContext from "../../contexts/music-player-context";

const HomePage = () => {
  const {
    token,
  } = useContext(AuthContext);
  const { startNew, setQueue } = useContext(MusicPlayerContext)
  const { feed, setFeed } = useContext(FeedContext);
  const { isLoggedIn, tokenClaim: { is_creator: isCreator } } = useContext(AuthContext);
  const navigate = useNavigate();

  const [highlights, setHighlights] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [isPaginationLoading, setIsPaginationLoading] = useState(false);
  const [canPaginate, setCanPaginate] = useState(true);
  const [hasError, setHasError] = useState(false);

  const onClickPlayAll = () => {
    startNew(feed[0]);
    setQueue(feed.slice(1));
  };

  const loadData = async () => {
    setHasError(false);

    if (feed === 0) {
      setIsLoading(true);
    } else {
      setIsPaginationLoading(true);
    }

    try {
      const {
        results: highlightData
      } = await listHighlights(null, { category: "home" });

      const {
        results: playalongsData,
        next: hasMorePlayalongs
      } = await listPlayalongs(token, {
        offset: feed.length,
        limit: 20,
      });

      const newCombinedResults = [
        ...playalongsData.sort((a, b) => new Date(b.published) - new Date(a.published))
      ]

      const newResults = [...feed, ...newCombinedResults];

      setHighlights(highlightData);
      setFeed(newResults);
      setCanPaginate(!!hasMorePlayalongs);
    } catch {
      console.log("error loading data");
      setHasError(true);
    } finally {
      setIsLoading(false);
      setIsPaginationLoading(false);
    }
  };

  useEffect(() => {
    setFeed([]);
    loadData();
  }, []);

  if (isLoading) return <HomeSkeleton />;

  return (
    <>

      {highlights.length > 0 && (
        <>
          <HeroCardCarousel highlights={highlights} />
        </>
      )
      }
      <br />
      <Row justify="space-between" align="top" style={{ marginBottom: 10 }}>
        <Col>
          <Typography.Title level={4} style={{ marginBottom: 0 }}>Recently Added</Typography.Title>
        </Col>
        <Col>
          <Button
            shape="round"
            icon={<CaretRightFilled />}
            ghost
            type="primary"
            onClick={onClickPlayAll}
          >
            Play All
          </Button>
        </Col>
      </Row>
      <Row gutter={[12, 12]}>
        {feed.map((r, i) => (
          <>
            <Col span={24}>
              <YTPlayalongCard ytPlayalong={r} />
            </Col>
            {
              (i + 1) % getAdFrequency() == 0 && (
                <InFeedAd key={r.id} />
              )
            }
          </>
        ))}

        {isPaginationLoading && (
          <>
            {[1, 2, 3].map((i) => {
              return (
                <Col key={i} span={24}>
                  <SkeletonJamtrackCard />
                </Col>
              );
            })}
          </>
        )}
      </Row>
      {
        canPaginate && !isPaginationLoading && (
          <>
            <br />
            <Row justify="center">
              <Col>
                <Button onClick={loadData} type="link">
                  Load More
                </Button>
              </Col>
            </Row>
          </>
        )
      }
    </>
  );
};

export default HomePage;
